import { classList } from '~/utilities/css';
import { InputProps } from './Input';

const styles = {
	label: (layout: InputProps['layout'], disabled: boolean) => classList([
		'uic-text-xxs',
		`${layout === 'stacked' ? 'uic-flex uic-flex-col uic-gap-2' : ''}`,
		`${layout === 'inline' ? 'uic-flex uic-justify-between uic-items-center uic-gap-x-10' : ''}`,
		`${disabled ? 'uic-cursor-not-allowed' : ''}`,
	]),

	input: (background: InputProps['background']) => classList([
		'mobile-only:uic-text-sm', // >= 16px font removes mobile input zoom
		'uic-rounded-md placeholder:uic-italic uic-flex-grow',
		'uic-min-h-[32px] uic-px-10',
		`${background === 'dark' ? 'uic-bg-white' : 'uic-bg-snow'}`,

		// ↓ Doesn't need to be conditionally applied via component (only works when logical property `disabled` has
		// been added to the input via pseudo class)
		'disabled:uic-pointer-events-none disabled:uic-opacity-30',
	]),
};

export { styles as default, styles };
