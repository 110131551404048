import { rem } from '~/utilities/css';
import styles from './styles';

// TODO: add correct eslint override for JSX global
type IntrinsicLabelProps = JSX.IntrinsicElements['label']; // eslint-disable-line no-undef
type IntrinsicInputProps = JSX.IntrinsicElements['input']; // eslint-disable-line no-undef

export type InputProps = {
	id: string,
	labelText: string,
	type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url' | 'search' | 'date' | 'time' | 'datetime-local' | 'month' | 'week',
	background?: 'light' | 'dark',
	disabled?: boolean,
	required?: boolean,
	fontSize?: number,
	boldLabel?: boolean,
	inlineLabelWidth?: number,
	intrinsicInputProps?: IntrinsicInputProps,
	intrinsicLabelProps?: IntrinsicLabelProps,
	name?: string,
	placeholderText?: string,
	layout?: 'inline' | 'stacked',
	'data-testid': string,
};

function Input({
	labelText,
	id,
	name,
	background = 'light',
	disabled = false,
	required = false,
	fontSize = 12,
	boldLabel = false,
	inlineLabelWidth = 75,
	placeholderText = 'Enter',
	layout = 'inline',
	type = 'text',
	intrinsicInputProps = {},
	intrinsicLabelProps = {},
	'data-testid': inputDataTestId = '',
}: InputProps) {
	/**
	 * `disabled` and `required` can be passed in directly but it can also come in through `intrinsicInputProps` (the former has
	 * a better DX 🔥). This function just cleans up the process of choosing to keep everything nice and tidy.
	 */
	const isDisabled = () => disabled || Object.prototype.hasOwnProperty.call(intrinsicInputProps, 'disabled');
	const isRequired = () => required || Object.prototype.hasOwnProperty.call(intrinsicInputProps, 'required');

	return (
		<label
			style={{ fontSize }}
			className={styles.label(layout, isDisabled())}
			htmlFor={id}
			{...intrinsicLabelProps} // eslint-disable-line react/jsx-props-no-spreading
		>
			<span
				style={layout === 'inline' ? { width: rem(inlineLabelWidth) } : {}}
				className={boldLabel ? 'uic-font-semibold' : ''}
			>{labelText}
			</span>

			<input
				type={type}
				name={name || id}
				id={id}
				className={styles.input(background)}
				placeholder={placeholderText}
				{...(isDisabled() ? { disabled: true } : {})} // eslint-disable-line react/jsx-props-no-spreading
				{...(isRequired() ? { required: true } : {})} // eslint-disable-line react/jsx-props-no-spreading
				{...intrinsicInputProps} // eslint-disable-line react/jsx-props-no-spreading
				data-testid={inputDataTestId}
			/>
		</label>
	);
}

export { Input as default, Input };
